<template>
	<div class="app-container">
		<ToolBar
			:dicts="dicts"
			@on-change="dealWithToolBarChange"
			:pageNo="pageNo"
			:pageSize="pageSize"
		></ToolBar>
		<CustomTable
			:dicts="dicts"
			:queryParams="queryParams"
			@getPageNo="getPageNo"
		></CustomTable>
	</div>
</template>

<script>
import ToolBar from "./ToolBar";
import CustomTable from "./CustomTable";
import { mapGetters } from "vuex";
import { GET_DICTS } from "@/store/constants";

export default {
	name: "terminalOperRecord",
	components: { CustomTable, ToolBar },
	computed: {
		...mapGetters({
			dicts: GET_DICTS
		})
	},
	data() {
		return {
			queryParams: {},
			pageNo: 1,
			pageSize: 10
		};
	},
	methods: {
		dealWithToolBarChange(params) {
			this.queryParams = params;
		},
		getPageNo(pageNo, pageSize) {
			this.pageNo = pageNo;
			this.pageSize = pageSize;
		}
	}
};
</script>

<style scoped></style>
